import React, { createContext, useContext, useState } from "react";

// Current
const CurrentContext = createContext();
const CurrentUpdateContext = createContext();

export const useCurrentContext = () => {
    return useContext(CurrentContext);
};

export const useCurrentUpdateContext = () => {
    return useContext(CurrentUpdateContext);
};

// Preview
const PreviewContext = createContext();
const PreviewUpdateContext = createContext();

export const usePreviewContext = () => {
    return useContext(PreviewContext);
};

export const usePreviewUpdateContext = () => {
    return useContext(PreviewUpdateContext);
};

// Filter
const FilterContext = createContext();
const FilterUpdateContext = createContext();

export const useFilterContext = () => {
    return useContext(FilterContext);
};

export const useFilterUpdateContext = () => {
    return useContext(FilterUpdateContext);
};

// Info Active
const InfoActiveContext = createContext();
const InfoActiveUpdateContext = createContext();

export const useInfoActiveContext = () => {
    return useContext(InfoActiveContext);
};

export const useInfoActiveUpdateContext = () => {
    return useContext(InfoActiveUpdateContext);
};

// Info Height
const InfoHeightContext = createContext();
const InfoHeightUpdateContext = createContext();

export const useInfoHeightContext = () => {
    return useContext(InfoHeightContext);
};

export const useInfoHeightUpdateContext = () => {
    return useContext(InfoHeightUpdateContext);
};

// Context Provider
const ContextProvider = ({ children }) => {
    const [current, setCurrent] = useState([]);
    const [preview, setPreview] = useState("");
    const [filter, setFilter] = useState([]);
    const [infoActive, setInfoActive] = useState({post: "", project: "", subtitle: ""});
    const [infoHeight, setInfoHeight] = useState(0);

    return (
        <CurrentContext.Provider value={current}>
            <CurrentUpdateContext.Provider value={setCurrent}>
                <PreviewContext.Provider value={preview}>
                    <PreviewUpdateContext.Provider value={setPreview}>
                        <FilterContext.Provider value={filter}>
                            <FilterUpdateContext.Provider value={setFilter}>
                                <InfoActiveContext.Provider value={infoActive}>
                                    <InfoActiveUpdateContext.Provider value={setInfoActive}>
                                        <InfoHeightContext.Provider value={infoHeight}>
                                            <InfoHeightUpdateContext.Provider value={setInfoHeight}>
                                                {children}
                                            </InfoHeightUpdateContext.Provider>
                                        </InfoHeightContext.Provider>
                                    </InfoActiveUpdateContext.Provider>
                                </InfoActiveContext.Provider>
                            </FilterUpdateContext.Provider>
                        </FilterContext.Provider>
                    </PreviewUpdateContext.Provider>
                </PreviewContext.Provider>
            </CurrentUpdateContext.Provider>
        </CurrentContext.Provider>
    );
};

export default ContextProvider;
