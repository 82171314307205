import React from "react";

const Arrow = ({ margin, invert }) => {
    return (
        <svg
            width="19.5"
            height="9.65"
            viewBox="0 0 19.5 9.65"
            style={{ width: "15px", marginLeft: `${margin}px` }}
        >
            <path
                d="M.53,3.62,4.12,7.21a5.76,5.76,0,0,0,8.16,0L18.75.75"
                fill="none"
                stroke={invert ? "#000000" : "var(--text-colour)"}
                strokeMiterlimit="10"
                strokeWidth="1.5"
                vectorEffect="non-scaling-stroke"
            />
            <path
                d="M10.53.75h8.22V9"
                fill="none"
                stroke={invert ? "#000000" : "var(--text-colour)"}
                strokeMiterlimit="10"
                strokeWidth="1.5"
                vectorEffect="non-scaling-stroke"
            />
        </svg>
    );
};

export default Arrow;
