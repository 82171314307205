import React from "react";
import GlobalState from "../state/GlobalState";
import Seo from "../components/seo/Seo";
import { PortableTextComponentsProvider } from "@portabletext/react";
import Arrow from "../components/icons/Arrow";

const components = {
    marks: {
        externalLink: ({ value, children }) => {
            const type = () => {
                switch (value.type) {
                    case "email":
                        return value.featured === true ? (
                            <a href={`mailto:${value.link}`}>
                                {children}
                                <Arrow margin={5} />
                            </a>
                        ) : (
                            <a href={`mailto:${value.link}`}>{children}</a>
                        );
                    case "phone":
                        return value.featured === true ? (
                            <a href={`tel:${value.link}`}>
                                {children}
                                <Arrow margin={5} />
                            </a>
                        ) : (
                            <a href={`tel:${value.link}`}>{children}</a>
                        );
                    default:
                        return value.featured === true ? (
                            <a href={`${value.link}`} target="_blank" rel="noreferrer">
                                {children}
                                <Arrow margin={5} />
                            </a>
                        ) : (
                            <a href={`${value.link}`} target="_blank" rel="noreferrer" style={{textDecoration:"underline"}}>
                                {children}
                            </a>
                        );
                }
            };
            return type();
        },
    },
};

const Layout = ({ children }) => {
    return (
        <GlobalState>
            <Seo />
            <PortableTextComponentsProvider components={components}>
                <main>{children}</main>
            </PortableTextComponentsProvider>
        </GlobalState>
    );
};

export default Layout;
